import { Component } from 'react';
import PropTypes from 'prop-types';

class DynamicImport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      component : null
    };

    this.settingComponent = false;
  }

  componentDidMount() {
    const { load } = this.props;
    this.settingComponent = true;
    load()
      .then(component => {
        if (this.settingComponent) {
          this.setState(() => ({
            component : component.default || component
          }));
          this.settingComponent = false;
        }
        return true;
      })
      .catch(error => error);
  }

  componentWillUnmount() {
    this.settingComponent = false;
  }

  render() {
    const { children } = this.props;
    const { component } = this.state;
    return children(component);
  }
}

DynamicImport.propTypes = {
  children : PropTypes.func.isRequired,
  load     : PropTypes.func.isRequired
};

export default DynamicImport;
