import { FeatureFlagApi } from '../api/Api';
import { featureFlagRoutes } from '../api/ApiRoutes';

const getFeatureFlagByFeatureKey = async featureKey => {
  try {
    const { data: { data } } = await FeatureFlagApi.get(featureFlagRoutes.GET_ACCOUNT_ACCESS_BY_FEATURE_KEY(featureKey));
    return data || false;
  } catch {
    return false;
  }
};
export {
  getFeatureFlagByFeatureKey
};
