import { useEffect } from 'react';
import * as R from 'ramda';
import { I18nApi } from '../api/Api';
import { translate } from '../helpers/i18nActions';
import { getUser } from '../helpers/userActions';

/* i18nifySimpleStrings * Convert an array of strings to the i18n translate format
 * I.e., ["Edit", "Delete this"] => {Edit : [], 'Delete this': []}
 *
 * NOTE: This only handles the simple case of no substitions (`%s`). If you
 * need this feature, you should enter that line separately and then
 * include the i18nify'd strings.
 *
 * Example:
 * import { i18nifySimpleStrings, useI18n } from '../utils/usei18n';
 *  const [i18n, setI18n] = useState({});
 *  const useI18nStrings = {
 *    strings : {
 *      singular : {
 *        ...i18nifySimpleStrings(['Delete', 'Cancel this'])
 *      }
 *    }
 *  };
 * Or define your array separately and then convert it in the call:
 * const myI18nStrings = ["Edit", "Delete this"];
 * const i18nStrings = i18nifySimpleStrings(myI18nStrings);
 *
 * Input:   Array of strings
 * Output:  i18n/translate strings formatted object
 * */
const i18nifySimpleStrings = R.reduce(
  (acc, string) => ({ ...acc, [string] : [] }),
  {}
);

/* useI18n
 * Call the i18n translate function with setI18n & i18nStrings object
 *
 * Example:
 * import { i18nifySimpleStrings, useI18n } from '../utils/useI18n';
 * const [i18n, setI18n] = useState({});
 * const useI18nStrings = <as described above>
 * useI18n(setI18n, useI18nStrings);
 *
 * Input:   setI18n function as you would normally use in the translate function
 * Input:   i18nify'd strings object [created by i18nifySimpleStrings above]
 */
const useI18n = (setI18n, { strings }) => {
  useEffect(() => {
    let isMounted = true;
    translate(I18nApi)({
      locale : getUser().language,
      strings
    }).then(data => {
      if (isMounted) setI18n(data);
    });
    // Disable when the component is unmounted
    return () => {
      isMounted = false;
    };
  }, []);
};

export { useI18n, i18nifySimpleStrings };
