import { error, errors } from '@businesswire/bw-node-error';
import { i18nRoutes } from '../api/ApiRoutes';

let translations = {};

const setTranslations = data => {
  translations = data;
};

const translate = I18nApi => async ({ locale, strings }) => {
  if (!locale || !strings) {
    throw error({
      code    : errors.client.PAYLOAD_NOT_ACCEPTABLE().statusCode,
      message : `Locale or strings not provided. locale: ${locale} / strings: ${strings}`
    });
  }

  const {
    data: { data }
  } = await I18nApi.post(i18nRoutes.TRANSLATE(), { locale, strings });

  return data;
};

const getLanguagesData = I18nApi => async () => {
  const {
    data: { data }
  } = await I18nApi.get(i18nRoutes.GET_LANGUAGES_DATA());
  return data;
};

const getTimezonesData = I18nApi => async () => {
  const {
    data: { data }
  } = await I18nApi.get(i18nRoutes.GET_TIMEZONES_DATA());
  return data;
};

const i18n = key => translations[key] || key;

export { i18n, getLanguagesData, getTimezonesData, setTranslations, translate };
