import { userActions } from '../actions/actionTypes';

const initState = {
  user                 : null,
  bannerAgreement      : false,
  preferencesAvailable : false,
  preferences          : {
    orderPreferences : {
      userReleaseTimeTimeZoneId : ''
    }
  },
  accessDenied : false
};

export default function reducer(state = initState, action) {
  switch (action.type) {
    case userActions.SET_USER: {
      return {
        ...state,
        user : action.payload
      };
    }
    case userActions.BANNER_AGREEMENT: {
      return {
        ...state,
        bannerAgreement : action.payload
      };
    }
    case userActions.SET_PREFERENCES: {
      return {
        ...state,
        preferencesAvailable : true,
        preferences          : action.payload
      };
    }
    case userActions.SET_USER_TIMEZONE_OFFSET: {
      return {
        ...state,
        timezoneOffset : action.payload
      };
    }
    case userActions.CLEAR_PREFERENCES: {
      return {
        ...state,
        preferencesAvailable : false,
        preferences          : initState.preferences
      };
    }
    default:
      return state;
  }
}
