import { featureFlagsActions } from '../actions/actionTypes';
import initialState from '../globals/featureFlags/initialState';

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case featureFlagsActions.SET_FEATURE_FLAGS: {
      return {
        ...state,
        ...action.payload
      };
    }
    default:
      return state;
  }
}
