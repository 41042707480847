import { i18nActions } from '../actions/actionTypes';

const initState = {
  translations : {}
};

export default function reducer(state = initState, action) {
  switch (action.type) {
    case i18nActions.SET_I18N: {
      return {
        ...state,
        translations : action.payload
      };
    }
    default:
      return state;
  }
}
