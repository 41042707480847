import { accountPickerActions } from '../actions/actionTypes';

const initialState = {
  accountsList    : [],
  selectedAccount : ''
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case accountPickerActions.STORE_SELECTED_ACCOUNT: {
      return {
        ...state,
        selectedAccount : action.payload
      };
    }
    case accountPickerActions.STORE_ACCOUNTS: {
      return {
        ...state,
        accountsList : action.payload
      };
    }
    default:
      return state;
  }
}
