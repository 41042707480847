import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import promise from 'redux-promise-middleware';
import { persistStore, persistReducer } from 'redux-persist';
import sessionStorage from 'redux-persist/lib/storage/session';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';

import reducers from './reducers/index';

const copyApprovalRootPersistConfig = {
  key       : 'copyApprovalRoot',
  storage   : sessionStorage,
  blacklist : []
};

const middleware = applyMiddleware(promise(), thunk);

const persistedReducer = persistReducer(
  copyApprovalRootPersistConfig,
  reducers
);

export default () => {
  const store = createStore(
    persistedReducer,
    composeWithDevTools(middleware)
    // middleware
  );
  const persistor = persistStore(store);

  return { store, persistor };
};
