const storageKeys = {
  BW_USER           : 'bwUser',
  BW_TOKEN          : 'bwToken',
  BW_REFRESH_TOKEN  : 'bwRefreshToken',
  BW_PUBLIC_KEYS    : 'bwPublicKeys',
  BW_DEPLOYMENT     : 'bwDeployment',
  ACCESS_DENIED_URL : 'accessDeniedUrl'
};

const untrackedStorageKeys = {
  BW_MFA_TOKEN    : 'bwMfaToken',
  EXIT_ERROR_CODE : 'exitErrorCode'
};

const reduxStorageKeys = {
  BW_REDUX_PERSIST : 'persist:root'
};

export { storageKeys, reduxStorageKeys, untrackedStorageKeys };
