import loadLanguagesInBackground from '../store/globals/languages/loadLanguagesInBackground';
import loadPreferencesInBackground from '../store/globals/client/loadPreferencesInBackground';
import loadAccountsInBackground from '../store/globals/client/loadAvailableAccountsInBackground';
import loadTimezonesInBackground from '../store/globals/timezones/loadTimezonesInBackground';
import loadUserTimezoneOffsetInBackground from '../store/globals/timezones/loadUserTimezoneOffsetInBackground';

export const loadDataInBackground = dispatch =>
  Promise.all([
    loadLanguagesInBackground(dispatch),
    loadPreferencesInBackground(dispatch),
    loadAccountsInBackground(dispatch),
    loadTimezonesInBackground(dispatch),
    loadUserTimezoneOffsetInBackground(dispatch)
  ]);
