const setStorageItem = async ({ key, value }) => {
  if (key && value) {
    window.sessionStorage.setItem(key, JSON.stringify(value));
  } else {
    await Promise.resolve();
  }
};

const removeStorageItem = key => window.sessionStorage.removeItem(key);

const getStorageItem = key => {
  const item = window.sessionStorage.getItem(key);
  try {
    return JSON.parse(item);
  } catch (e) {
    return item;
  }
};

export { getStorageItem, removeStorageItem, setStorageItem };
