import { getPreferences } from '../../../helpers/clientActions';
import { setPreferences } from '../../actions/userActions';

const loadPreferencesInBackground = dispatch => {
  const fetchPreferences = async () => {
    const response = await getPreferences();
    dispatch(setPreferences(response || {}));
  };

  fetchPreferences();
};

export default loadPreferencesInBackground;
