import { accountPickerActions } from './actionTypes';

const storeSelectedAccount = payload => ({
  type : accountPickerActions.STORE_SELECTED_ACCOUNT,
  payload
});

const storeAccountsList = payload => ({
  type : accountPickerActions.STORE_ACCOUNTS,
  payload
});

export {
  storeSelectedAccount,
  storeAccountsList
};
