import dayjs from '../utils/dayjsExtension';
import { getQueryParams } from '../utils/getQueryParams';

const authRoutes = {
  LOGIN            : () => '/auth/login',
  CHECK_PERMISSION : (
    resource,
    requestType,
    cloudUserId,
    tenantId = '-',
    tenantOrganizationId = '-'
  ) =>
    `/api/v1/auth/tenantAccessPermission/public/check/${btoa(
      resource
    )}/${requestType}/${cloudUserId}/${tenantId}/${tenantOrganizationId}`,
  REFRESH : () => '/auth/refresh'
};

const i18nRoutes = {
  TRANSLATE          : () => '/i18n/translate',
  GET_LANGUAGES_DATA : () => '/i18n/language',
  GET_TIMEZONES_DATA : () => '/i18n/timezone'
};

const clientRoutes = {
  GET_PROFILE     : () => '/client/profile',
  LOOKUP_ACCOUNTS : () => '/client/account/lookup',
  GET_PREFERENCES : () => '/client/preferences'
};

const DEFAULT_PAGE_SIZE = 10;
const OLDEST_ALLOWED_DATE = 30;
const currentDate = dayjs.utc().format('YYYY-MM-DDTHH:mm:ss');
const thirtyDaysAgo = dayjs.utc().subtract(OLDEST_ALLOWED_DATE, 'day').format('YYYY-MM-DDTHH:mm:ss');

const pressReleaseRoutes = {
  GET_PRESS_RELEASES_BYACCOUNT_ID : (accountId, page = 1, pageSize={DEFAULT_PAGE_SIZE}) => `pressRelease/release/accountId/${accountId}?page=${page}&pageSize=${pageSize}&thruDate=${currentDate}&fromDate=${thirtyDaysAgo}`,
  GET_ENGAGEMENT_REPORTS_BY_DAYS  : days => `pressRelease/engagement/latest?days=${days}`
};

const systemRoutes = {
  GET_MAINTENANCE_WINDOWS : () => '/system/maintenance/public/windows'
};

const featureFlagRoutes = {
  GET_ACCOUNT_ACCESS_BY_FEATURE_KEY : featureKey => `featureFlag/accountFeature/featureKey/${featureKey}`
};

const mediaRoutes = {
  GET_S3_PRESIGNED_URL_MEDIA_ASSET_BY_GROUP_ID_VARIANT_ID : (groupId, variantId) => `/media/preSignedUrl/groupId/${groupId}/variantId/${variantId}`
};

const orderRoutes = {
  GET_RELEASES_BY_GIVEN_STATUS : params => {
    const { status } = params;
    const queryParams = getQueryParams({ params, omit : ['status'] });

    return queryParams.length
      ? `order/order/releases/status/${status}?${queryParams}`
      : `order/order/releases/status/${status}`;
  }
}

export {
  authRoutes,
  clientRoutes,
  i18nRoutes,
  pressReleaseRoutes,
  systemRoutes,
  featureFlagRoutes,
  orderRoutes,
  mediaRoutes
};
