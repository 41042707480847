import * as R from 'ramda';
/* eslint-disable no-param-reassign */

/* createClassName
 *  Generate a testing class name of the form `sweord-${identifier}-${name}`
 *    plus a corresponding UI class name of the form `${identifier}-${name}`
 *
 *  identifier String  - module identification string based on your component's name
 *  name       String  - unique component name
 *
 *  NOTE: this will be easy to use if you generate a "local" method in your component, such as:
 *    for component InteractiveMediaModal:
 *    const createMyClassName = createClassName('interactive-media-modal');
 *  and then use it like so:
 *     className={createMyClassName('modal')};
 * Alternatively, you can just do:
 *     className={createClassName('button')('cancel')}
 * if the "identifier" changes several times.
 */
export const createClassName = identifier => name => `${identifier}-${name} sweord-${identifier}-${name}`;

// Get Tier in URL
const urlPrefix = 'https://www.';
const urlSuffix = 'businesswire.com';
const urlTier = window.BW.C4_TIER;
export const corporateUrl = `${urlPrefix}${
  urlTier !== 'prod' ? `${urlTier}${'.'}` : ''
}${urlSuffix}`;

export const scrollTop = (top = 0, left = 0) => {
  // NOTE: There's also window.scroll()
  window.scrollTo({
    top,
    left,
    behavior : 'auto'
  });
};

export const oppositeDirection = direction => (direction === 'ascending' ? 'descending' : 'ascending');

export const addEllipsis = (fileName, length) => {
  if (fileName.length > length) {
    const FILENAME_END_INDEX = 40;
    return `${fileName.substring(0, FILENAME_END_INDEX)}...`;
  }
  return fileName;
};

export const decodeHTMLEntities = str => str.replace(/\u00a0/g, ' ');

export const replaceNewLine = str => JSON.stringify(str, (key, value) => R.replace(/\n/g, '<br>')(value));

export const encodeHtmlTags = str => R.compose(
  R.replace(/</g, '&lt;'),
  R.replace(/>/g, '&gt;')
)(str);

export const SCHEDULE_TYPES = {
  RELEASE_TIME_IMMEDIATE : 1,
  RELEASE_TIME_HOLD      : 2,
  RELEASE_TIME_SPECIFIC  : 3
};

export const TRANSMISSION_TYPES = {
  IMMEDIATE : 'IMD',
  HOLD      : 'HLD',
  FTR       : 'FTR'
};

export const ORDER_STATE = {
  SENT        : 'SENT',
  PENDING     : 'PENDING',
  CANCELED    : 'CANCELED',
  NON_BW_SENT : 'NON_BW_SENT'
};

export const selectMainRelease = (item, keyProp) => {
  const { newsItems, mainNewsItemId } = item;
  if (!newsItems) return null;
  const indexOfMain = R.findIndex(R.propEq('newsItemId', mainNewsItemId))(newsItems);
  return newsItems[indexOfMain][keyProp];
};

export const capitalizeAnUppercaseString = string => string?.toLowerCase().replace(/[_]/g, ' ').replace(/\b\w/g, word => word.toUpperCase());

export const largeNumberFormatter = (num, digits) => {
  const lookup = [
    { value : 1, symbol : "" },
    { value : 1e3, symbol : "K" },
    { value : 1e6, symbol : "M" },
    { value : 1e9, symbol : "G" },
    { value : 1e12, symbol : "T" },
    { value : 1e15, symbol : "P" },
    { value : 1e18, symbol : "E" }
  ];
  const replaceNumber = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const item = lookup.slice().reverse().find(_item => num >= _item.value);
  return item ? (num / item.value).toFixed(digits).replace(replaceNumber, "$1") + item.symbol : "0";
}
