import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Radio } from 'antd';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import breakpoints from '@businesswire/bw-web-styles/src/styles/breakpoints/breakpointsNewsTrak';
import { SideNavigation, Footer } from '@businesswire/bw-global-components';
import LoggedInTopNavigation from './navigation/LoggedInTopNavigation/LoggedInTopNavigation';
import { isLoggedIn, getUser } from '../../../helpers/userActions';
import { TopNavigation } from './navigation/TopNavigation';

const Layout = styled.div`
  max-width: 1366px;
  margin: 0 auto;
`;

const mediumBreakpointNum = parseInt(breakpoints['@medium'].replace('px', ''), 10);

const breakpointPlusOne = mediumBreakpointNum ?
  (`${mediumBreakpointNum + 1}px`):
  "993px";

const Content = styled.div(
  ({ authenticatedRoute }) => `
    @media only screen and (min-width: ${breakpointPlusOne}) {
      margin-left: ${authenticatedRoute ? '220px' : 0};
    }
`);

connect(store => ({
  user : store.user
}));
class Wrapper extends PureComponent {
  constructor(props) {
    super(props);

    const { authenticatedRoute } = this.props;
    const isAuthenticated = isLoggedIn();

    if (authenticatedRoute && !isAuthenticated) {
      window.location.href = `${
        window.BW.LOGIN_URL
      }?redirect_url=${encodeURIComponent(window.location.href)}`;
    }
  }

  render() {
    const { authenticatedRoute } = this.props;
    const isAuthenticated = isLoggedIn();

    if (authenticatedRoute && !isAuthenticated) {
      return null;
    }

    const { children } = this.props;
    const userInfo = getUser();
    const menuKeys = [window.BW.COPY_APPROVAL_URL];
    const currentYear = new Date().getFullYear();

    return (
      <>
        {isAuthenticated ? (
          <>
            <LoggedInTopNavigation />
            <Layout>
              <Radio style={{ display : 'none' }} />
              <SideNavigation menuKeys={menuKeys} />
              <Content authenticatedRoute={authenticatedRoute}>
                {children}
                <Footer><div>©{currentYear} Business Wire</div></Footer>
              </Content>
            </Layout>
          </>
        ) : (
          <Layout>
            <TopNavigation
              isAuthenticated={isAuthenticated}
              userInfo={userInfo}
            />
            <Content authenticatedRoute={authenticatedRoute}>
              {children}
            </Content>
          </Layout>
        )}
      </>
    );
  }
}

Wrapper.propTypes = {
  authenticatedRoute : PropTypes.bool.isRequired,
  children           : PropTypes.node.isRequired,
  history            : PropTypes.shape({}).isRequired
};

export default Wrapper;
