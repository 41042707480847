import React, { useState } from 'react';
import { withRouter } from 'react-router';
import { Layout, Input } from 'antd';
import styled from '@emotion/styled';
import colors from '@businesswire/bw-web-styles/src/styles/variables/colors';
import colorsV2 from '@businesswire/bw-web-styles/src/styles/variables/colorsV2';
import breakpoints from '@businesswire/bw-web-styles/src/styles/breakpoints/breakpoints';
import bwLogoLarge from './logo.svg';
import bwLogoSmall from './logoSmall.svg';
import { corporateUrl } from '../../../../../utils/utils';

const { Header } = Layout;
const { Search } = Input;

const HeaderContainer = styled(Header)`
  border-bottom: solid 1px ${colors['@light-border']};
  background-color: ${colors['@white']};
  height: 90px;
  width: 100%;
  min-width: ${breakpoints['@xsmall']};
  position: fixed;
  top: 0;
  z-index: 2;
  @media only screen and (max-width: ${breakpoints['@medium']}) {
    &.collapsed-nav-header {
      height: 52px;
    }
    &.open-nav-header {
      height: 309px;
    }
  }
`;

const HeaderInnerContainer = styled.div`
  max-width: 1366px;
  margin: 0 auto;
  display: flex;
  a {
    line-height: 90px;
    color: ${colors['@light-navy']};
    font-weight: bold;
    @media only screen and (max-width: ${breakpoints['@medium']}) {
      line-height: 44px;
    }
  }
  .collapsed-user-menu {
    display: inline-block;
    width: 100px;
  }
`;

const SearchContainer = styled.a`
  height: 36px;
  min-width: 200px;
  line-height: 90px;
  padding-left: 2em;
  margin-right: 20px;
  @media only screen and (max-width: ${breakpoints['@medium']}) {
    display: none;
  }
`;

const SearchContainerSmall = styled.a`
  display: none;
  @media only screen and (max-width: ${breakpoints['@medium']}) {
    display: inline-block;
    margin-right: -13px;
    input {
      display: none;
    }
    .bw-input-search-icon {
      background-color: ${colors['@light-navy']};
      color: ${colors['@white']};
      height: 52px;
      width: 42px;
      padding-top: 17px;
      &:hover {
        background-color: ${colors['@bw-blue']};
      }
    }
  }
`;

const MobileContainer = styled.div`
  display: flex;
  justify-content: space-between;
  a {
    padding-left: 10px;
  }
  @media only screen and (max-width: ${breakpoints['@medium']}) {
    position: absolute;
    right: 0;
    a:nth-of-type(2) {
      margin-right: 4.5em;
    }
  }
`;

const CollapsedMenu = styled.div`
  display: none;
  @media only screen and (max-width: ${breakpoints['@medium']}) {
    display: block;
    background-color: ${colors['@light-navy']};
    width: 42px;
    height: 52px;
    cursor: pointer;
    padding: 10px 0 0 10px;
    &:hover {
      background-color: ${colors['@bw-blue']};
    }
    div {
      background-color: ${colors['@white']};
      height: 3px;
      width: 25px;
      margin: 5px 0;
    }
  }
`;

const LogoContainer = styled.div`
  img {
    max-width: 170px;
  }
  .logged-out-logo-large {
    display: inline-block;
  }
  .logged-out-logo-small {
    display: none;
  }
  @media only screen and (max-width: ${breakpoints['@medium']}) {
    img {
      position: absolute;
      top: 5px;
      left: 20px;
      width: 200px;
    }
    .logged-out-logo-large {
      display: none;
    }
    .logged-out-logo-small {
      display: inline-block;
      margin-top: 8px;
    }
  }
`;

const NavItems = styled.div`
  margin: 0 4em;
  a {
    padding-left: 20px;
  }
  @media only screen and (max-width: ${breakpoints['@medium']}) {
    margin: 0;
    &.collapsed-nav-items {
      display: none;
    }
    &.open-nav-items {
      position: absolute;
      top: 52px;
      left: 0;
      background-color: ${colors['@white']};
      height: 400px;
      a {
        display: block;
        text-align: right;
        width: 100vw;
        min-width: ${breakpoints['@xsmall']};
        padding: 3px 10px;
        border-bottom: 1px solid ${colorsV2['@text-links-border']};
        background-color: ${colors['@white']};
        &:first-of-type {
          border-top: 1px solid ${colorsV2['@text-links-border']};
        }
        &:hover {
          background-color: ${colors['@light-navy']};
          color: ${colors['@white']};
        }
      }
    }
  }
`;

export const TopNavigation = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <HeaderContainer
      className={
        isExpanded === false ? 'collapsed-nav-header' : 'open-nav-header'
      }
    >
      <HeaderInnerContainer>
        <LogoContainer>
          <a href={corporateUrl} title="Homepage">
            <img
              alt="Business Wire Logo"
              src={bwLogoLarge}
              className="logo logged-out-logo-large"
            />
            <img
              alt="Business Wire Logo"
              src={bwLogoSmall}
              className="logo logged-out-logo-small"
            />
          </a>
        </LogoContainer>
        <NavItems
          className={
            isExpanded === false ? 'collapsed-nav-items' : 'open-nav-items'
          }
        >
          <a href="/" title="Home">
            HOME
          </a>
          <a href="https://services.businesswire.com/" title="Services">
            SERVICES
          </a>
          <a href={`${corporateUrl}/portal/site/home/news/`} title="News">
            NEWS
          </a>
          <a
            href={`${corporateUrl}/portal/site/home/education/`}
            title="Education"
          >
            EDUCATION
          </a>
          <a href={`${corporateUrl}/portal/site/home/about/`} title="About Us">
            ABOUT US
          </a>
        </NavItems>
        <SearchContainer
          href={`${corporateUrl}/portal/site/home/search/`}
          title="Go to Search Page"
        >
          <Search placeholder="Search" />
        </SearchContainer>
        <MobileContainer>
          <a
            href={`${window.BW.LOGIN_URL}?redirect_url=${encodeURIComponent(
              window.location.href
            )}`}
            title="Login"
          >
            Login
          </a>
          <a href={`${corporateUrl}/portal/site/home/signup/`} title="Sign Up">
            Sign Up
          </a>
          <SearchContainerSmall
            href={`${corporateUrl}/portal/site/home/search/`}
            title="Go to Search Page"
            className="search-container-small"
          >
            <Search placeholder="Search" />
          </SearchContainerSmall>
          <CollapsedMenu
            role="button"
            tabIndex={0}
            aria-controls="menu"
            aria-expanded={isExpanded.toString()}
            onClick={() => setIsExpanded(!isExpanded)}
            onKeyDown={() => setIsExpanded(!isExpanded)}
          >
            <div />
            <div />
            <div />
          </CollapsedMenu>
        </MobileContainer>
      </HeaderInnerContainer>
    </HeaderContainer>
  );
};
export default withRouter(TopNavigation);
