import * as R from 'ramda';
import * as RA from 'ramda-adjunct';

export const getQueryParams = ({params, omit = []}) => R.compose(
  R.join('&'),
  R.map(key => `${key}=${params[key]}`),
  R.reject(key => RA.isNilOrEmpty(params[key])),
  R.keys,
  R.omit(omit)
)(params);
