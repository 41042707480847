import { languagesActions } from '../actions/actionTypes';
import languagesInitialState from '../globals/languages/initialState';

export default function reducer(state = languagesInitialState, action) {
  switch (action.type) {
    case languagesActions.STORE_LANGUAGES_RESET: {
      return languagesInitialState;
    }
    case languagesActions.STORE_LANGUAGES_DATA: {
      return action.payload;
    }
    default:
      return state;
  }
}
