import { timezonesActions } from '../actions/actionTypes';
import timezonesInitialState from '../globals/timezones/initialState';

export default function reducer(state = timezonesInitialState, action) {
  switch (action.type) {
    case timezonesActions.STORE_TIMEZONES_RESET: {
      return timezonesInitialState;
    }
    case timezonesActions.STORE_TIMEZONES_DATA: {
      return action.payload;
    }
    default:
      return state;
  }
}
