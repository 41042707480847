import { languagesActions } from './actionTypes';

const storeReset = () => ({
  type : languagesActions.STORE_LANGUAGES_RESET
});

const storeLanguages = payload => ({
  type : languagesActions.STORE_LANGUAGES_DATA,
  payload
});

export { storeReset, storeLanguages };
