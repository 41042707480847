import { ClientApi } from '../api/Api';
import { clientRoutes } from '../api/ApiRoutes';

const getProfile = async () => {
  const {
    data: { data }
  } = await ClientApi.get(clientRoutes.GET_PROFILE());
  return data;
};

const getAccounts = async () => {
  const {
    data: { data }
  } = await ClientApi.get(clientRoutes.LOOKUP_ACCOUNTS());
  return data;
};

const getPreferences = async () => {
  const {
    data: { data }
  } = await ClientApi.get(clientRoutes.GET_PREFERENCES());
  return data;
};

const createSocialMediaPreferences = async socialMediaPreferences => {
  const {
    data: { data }
  } = await ClientApi.post(clientRoutes.CREATE_SOCIAL_MEDIA_PREFERENCES(), {
    socialMediaPreferences
  });

  return data;
};

export {
  getProfile,
  getAccounts,
  getPreferences,
  createSocialMediaPreferences
};
