import { getTimezonesData } from '../../../helpers/i18nActions';
import { I18nApi } from '../../../api/Api';
import { storeTimezones } from '../../actions/timezonesActions';

const loadTimezonesInBackground = dispatch => {
  const fetchLanguages = async () => {
    const response = await getTimezonesData(I18nApi)();
    dispatch(storeTimezones(response || null));
  };

  fetchLanguages();
};

export default loadTimezonesInBackground;
