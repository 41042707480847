import React from 'react';
import PropTypes from 'prop-types';
import { Spin } from 'antd';

/** Loading component
 * Any text to be displayed must be translated by the caller
 */
const Loading = props => {
  const { text, size } = props;

  return <Spin size={size} tip={text || null} />;
};

Loading.propTypes = {
  text : PropTypes.string,
  size : PropTypes.string
};

Loading.defaultProps = {
  text : ''
};

export default Loading;
