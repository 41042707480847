import React from 'react';
import { TopNavigation } from '@businesswire/bw-global-components';
import { logOut } from '../../../../../helpers/userActions';

const LoggedInTopNavigation = () => {
  const logUserOut = () => {
    logOut();
  };

  return <TopNavigation postLogoutCleanup={logUserOut} />;
};

export default LoggedInTopNavigation;
