import { getAccounts } from '../../../helpers/clientActions';
import { storeAccountsList } from '../../actions/accountPickerActions';

const loadAccountsInBackground = dispatch => {
  const fetchAccounts = async () => {
    const response = await getAccounts();
    dispatch(storeAccountsList(response || []));
  };

  fetchAccounts();
};

export default loadAccountsInBackground;
