const userActions = {
  SET_USER                 : 'SET_USER',
  LOGOUT_USER              : 'LOGOUT_USER',
  BANNER_AGREEMENT         : 'BANNER_AGREEMENT',
  SET_PREFERENCES          : 'SET_PREFERENCES',
  SET_USER_TIMEZONE_OFFSET : 'SET_USER_TIMEZONE_OFFSET'
};

const languagesActions = {
  STORE_LANGUAGES_RESET : 'STORE_LANGUAGES_RESET',
  STORE_LANGUAGES_DATA  : 'STORE_LANGUAGES_DATA'
};

const i18nActions = {
  SET_I18N : 'SET_I18N'
};

const pressReleaseActions = {
  STORE_PRESS_RELEASES           : 'STORE_PRESS_RELEASES',
  STORE_PRESS_RELEASES_BY_STATUS : 'STORE_PRESS_RELEASES_BY_STATUS'
};

const accountPickerActions = {
  STORE_ACCOUNTS         : 'STORE_ACCOUNTS',
  STORE_SELECTED_ACCOUNT : 'STORE_SELECTED_ACCOUNT'
};

const paginationActions = {
  STORE_PAGE_NUMBER : 'STORE_PAGE_NUMBER',
  STORE_PAGE_SIZE   : 'STORE_PAGE_SIZE'
};

const maintenanceActions = {
  SET_MAINTENANCE_WINDOWS : 'SET_MAINTENANCE_WINDOWS'
};

const featureFlagsActions = {
  SET_FEATURE_FLAGS : 'SET_FEATURE_FLAGS'
};

const engagementReportsActions = {
  STORE_ENGAGEMENT_REPORTS : 'STORE_ENGAGEMENT_REPORTS'
};

const timezonesActions = {
  STORE_TIMEZONES_RESET : 'STORE_TIMEZONES_RESET',
  STORE_TIMEZONES_DATA  : 'STORE_TIMEZONES_DATA'
};

export {
  i18nActions,
  languagesActions,
  userActions,
  pressReleaseActions,
  accountPickerActions,
  paginationActions,
  maintenanceActions,
  featureFlagsActions,
  timezonesActions,
  engagementReportsActions
};
