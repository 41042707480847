/* eslint-disable react/prop-types */
import React, {useEffect} from 'react';
import { Provider } from 'react-redux';
import axe from '@axe-core/react';
import ReactDOM from 'react-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { BrowserRouter, Switch } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import { TransferConsumer } from '@businesswire/bw-c5-components';

import Provision from './Provision';
import I18nProvider from './components/I18nProvider/I18nProvider';
import { routes } from './routing/routes';
import {
  createRouteComponentsFromRoutes,
  createRoute404Component
} from './routing/routing';
import configureStore from './store/store';
import 'normalize.css';
import './overrides.css';
import '@businesswire/bw-web-styles/src/styles/antd/index.less';

const DEBOUNCING_MS = 1000;
const AXE_INITIALIZE_MS = 5000

const { store, persistor } = configureStore();

const App = () => {
  const Routes = createRouteComponentsFromRoutes(routes);
  const Route404 = createRoute404Component(routes.PAGE_NOT_FOUND);

  useEffect(() => {
    setTimeout( ()=> {
      if (
        window.BW.C5_TIER === 'dev' ||
        window.BW.C5_TIER === 'int' ||
        window.BW.C5_TIER === 'local'
      ) {
        axe(React, ReactDOM, DEBOUNCING_MS);
      }
    }, AXE_INITIALIZE_MS)
  });

  return (
    <TransferConsumer>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter>
            <ConfigProvider prefixCls="bw">
              <Provision>
                <I18nProvider>
                  <Switch>
                    {Routes}
                    {Route404}
                  </Switch>
                </I18nProvider>
              </Provision>
            </ConfigProvider>
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </TransferConsumer>
  );
};

export default App;
