import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import * as R from 'ramda';

import { translate, setTranslations } from '../../helpers/i18nActions';
import { ensurePermission, logOut, getUser } from '../../helpers/userActions';
import { I18nApi, AuthApi } from '../../api/Api';
import { setI18n } from '../../store/actions/i18nActions';

import Loading from '../Loading/Loading';
import translationStrings from './translationStrings';

const I18nProvider = props => {
  const { children } = props;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const translations = useSelector(state => state.i18n.translations);

  const fail = () =>
    logOut(() => {
      window.location.href = window.BW.LOGIN_URL;
    });

  useEffect(() => {
    if (!R.isEmpty(translations)) {
      setTranslations(translations);
      setLoading(false);
    }
  }, [translations]);

  useEffect(() => {
    const user = getUser();

    if (user && user.id) {
      ensurePermission({
        user,
        AuthApi,
        success : () =>
          translate(I18nApi)({
            locale  : user.language,
            strings : translationStrings
          }).then(data => dispatch(setI18n(data))),
        fail
      });
    } else {
      fail();
    }
  }, []);

  return <div>{loading ? <Loading /> : children}</div>;
};

I18nProvider.propTypes = {
  children : PropTypes.node.isRequired
};

export default I18nProvider;
