import { engagementReportsActions } from "../actions/actionTypes";

const initialState =  {
    latest : {},
    totals : {},
    trends : {}
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case engagementReportsActions.STORE_ENGAGEMENT_REPORTS: {
      return action.payload;
    }
    default:
      return state;
  }
}