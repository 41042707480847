/* eslint-disable import/no-cycle */
const Dashboard = () => import('../containers/Dashboard/Dashboard');
const pageNotFound = () => import('../containers/404/PageNotFound');

const pathWithPrefix = appPrefix => path => `${appPrefix}${path}`;
const getAppPath = pathWithPrefix('/dashboard');

export const routes = {
  PAGE_NOT_FOUND : {
    name               : '404',
    component          : pageNotFound,
    path               : getAppPath('/404'),
    authenticatedRoute : false,
    showTemplate       : true
  },
  DEFAULT_LANDING : {
    name               : 'Dashboard',
    component          : Dashboard,
    path               : '/',
    authenticatedRoute : true,
    showTemplate       : true
  },
  DASHBOARD : {
    name               : 'Dashboard',
    component          : Dashboard,
    path               : '/dashboard',
    authenticatedRoute : true,
    showTemplate       : true
  }
};
