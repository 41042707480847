/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import qs from 'query-string';
import LogRocket from 'logrocket';
import { AuthApi } from './api/Api';

import {
  getExitErrorCode,
  clearExitErrorCode,
  ensurePermission,
  logOut,
  getUser
} from './helpers/userActions';

import Loading from './components/connect5/Loading/Loading';

import { setUser } from './store/actions/userActions';
import { storeReset as resetLanguagesAction } from './store/actions/languagesActions';

import { loadDataInBackground } from './utils/loadDataInBackground';
import { fetchFeatureFlags } from './store/globals/featureFlags/fetchFeatureFlags';

const cleanUrl = a => {
  const parsedUrl = qs.parseUrl(a);
  delete parsedUrl.query.t;
  return qs.stringifyUrl(parsedUrl, { encode : false });
};

const Provision = ({ children }) => {
  const dispatch = useDispatch();
  const [provisionedChildren, setProvisionedChildren] = useState(
    <Loading size="large" />
  );

  const maybeStartLogRocket = (userEmail, isLogRocketEnabled) => {
    if (!isLogRocketEnabled) {
      return;
    }
    // This allows all data to flow to Log Rocket so we can test it on DEV and INT
    // DO NOT enable this implementation on prod without data privacy measures added
    LogRocket.init('nx6zhl/businesswirepoc1');
    LogRocket.identify(userEmail);
  };

  const fail = () =>
    logOut(() => {
      const errorCode = getExitErrorCode();
      clearExitErrorCode();
      window.location.href = `${
        window.BW.LOGIN_URL
      }?redirect_url=${encodeURIComponent(
        cleanUrl(window.location.href)
      )}&errorCode=${errorCode}`;
    });

  useEffect(() => {
    const user = getUser();

    if (user && user.id) {
      ensurePermission({
        user,
        AuthApi,
        success : () =>
          Promise.all([
            dispatch(setUser(user)),
            dispatch(resetLanguagesAction())
          ])
            .then(() => Promise.resolve(loadDataInBackground(dispatch)))
            .then(() => Promise.resolve(fetchFeatureFlags(dispatch)))
            .then(featureFlags => maybeStartLogRocket(user.email, featureFlags?.['log-rocket']))
            .then(() => setProvisionedChildren(children)),
        fail
      });
    } else {
      fail();
    }
  }, []);

  return <>{provisionedChildren}</>;
};

export default Provision;
