import * as R from 'ramda';
import axios from 'axios';
import uuidv4 from 'uuid/v4';
import { errors } from '@businesswire/bw-node-error';

import {
  getAuthToken,
  getDeployment,
  maybeLoginWithRefreshToken,
  logOut
} from '../helpers/userActions';

import { handleError, handleUnauthenticated } from '../helpers/errorHandler';

const activeRequests = {};

const X_REQUEST_ID = 'x-requestid';

const getJWT = () => {
  const authToken = getAuthToken();

  if (!authToken) {
    return null;
  }

  return authToken;
};
const ERROR_TIMEOUT_INTERVAL = 10000;
const getApiTrackingUuid = () => uuidv4();

const getAxiosInstance = ({ url, port, versionPath = '', params }) => {
  const DECIMAL_BASE = 10;
  const DEFAULT_TIMEOUT = 60000;
  const config = {
    params,
    baseURL : `${url}:${port}${versionPath}`,
    timeout : R.compose(
      /* eslint-disable no-restricted-globals */
      R.when(isNaN, R.always(DEFAULT_TIMEOUT)),
      R.partialRight(parseInt, [DECIMAL_BASE]),
      R.propOr(DEFAULT_TIMEOUT, 'REACT_APP_API_TIMEOUT')
    )(process.env),
    headers : {
      'cache-control' : 'no-store, no-cache, must-revalidate'
    }
  };

  const Api = axios.create(config);

  Api.interceptors.request.use(reqConfig => {
    const updatedReqConfig = { ...reqConfig };

    const authToken = getJWT();
    const requestId = getApiTrackingUuid();
    const deployment = getDeployment();

    if (authToken) {
      updatedReqConfig.headers.authorization = `Bearer ${authToken}`;
    }

    updatedReqConfig.headers[X_REQUEST_ID] = requestId;
    updatedReqConfig.headers['x-deployment'] = deployment;

    activeRequests[requestId] = updatedReqConfig;

    return updatedReqConfig;
  });

  Api.interceptors.response.use(
    response => {
      const { data } = response;
      const requestId = response.headers[X_REQUEST_ID];

      if (data.data && data.data.err) {
        throw data.data.err;
      }

      if (!activeRequests[requestId]) {
        return handleUnauthenticated({
          error : errors.security.REQUEST_ID_MISMATCH({
            debug : {
              receivedRequestId : requestId
            }
          })
        });
      }

      if (data.data) delete activeRequests[requestId];

      return response || undefined;
    },
    error => {
      const _error = {
        error : {
          code : R.pathOr(errors.system.UNCAUGHT().code, ['response', 'status'])(
            error
          ),
          message : R.pathOr(errors.system.UNCAUGHT().message, [
            'response',
            'statusText'
          ])(error)
        },
        source : 'API Interceptor'
      };

      return new Promise((resolve, reject) => {
        if (
          R.pathEq(
            ['response', 'data', 'data', 'err', 'code'],
            errors.db.ACCESS_DENIED().code
          )(error)
        ) {
          const timeout = setTimeout(() => {
            handleError(_error);
            reject(error);
          }, ERROR_TIMEOUT_INTERVAL);

          Api.request(activeRequests[error.response.headers[X_REQUEST_ID]])
            .then(resolve)
            .catch(reject)
            .finally(() => clearTimeout(timeout));
        } else if (
          R.pathEq(
            ['response', 'data', 'data', 'code'],
            errors.auth.TOKEN_EXPIRED().code
          )(error)
        ) {
          const timeout = setTimeout(() => {
            handleError(_error);
            reject(error);
          }, ERROR_TIMEOUT_INTERVAL);

          maybeLoginWithRefreshToken(
            getAxiosInstance({
              url  : window.BW.AUTH_API_REST_URL,
              port : window.BW.AUTH_API_REST_PORT
            })
          )()
            .then(() => {
              Api.request(activeRequests[error.response.headers[X_REQUEST_ID]])
                .then(resolve)
                .catch(reject);
            })
            .catch(reject)
            .finally(() => clearTimeout(timeout));
        } else {
          const expectedAuthErrorCode = R.compose(
            R.length,
            R.intersection([
              errors.security.REQUEST_ID_MISMATCH().code,
              errors.auth.REFRESH_TOKEN_INVALID().code,
              errors.auth.REFRESH_TOKEN_EXPIRED().code,
              errors.auth.TOKEN_INVALID().code,
              errors.auth.TOKEN_EXPIRED().code
            ])
          );
          const possibleErrorCodes = R.paths([
            ['response', 'data', 'data', 'code'],
            ['response', 'data', 'data', 'err', 'code']
          ]);

          const isExpectedAuthErrorCode = R.compose(
            expectedAuthErrorCode,
            possibleErrorCodes
          );

          if (isExpectedAuthErrorCode(error)) {
            logOut();
            handleUnauthenticated({
              error  : 'Token Expired or Invalid',
              source : 'Axios Interceptor'
            });
          }
          handleError(_error);
          reject(error);
        }
      });
    }
  );

  return Api;
};

const OrderApi = getAxiosInstance({
  url         : window.BW.ORDER_API_REST_URL,
  port        : window.BW.ORDER_API_REST_PORT,
  versionPath : window.BW.ORDER_API_REST_VERSION
});

const AuthApi = getAxiosInstance({
  url  : window.BW.AUTH_API_REST_URL,
  port : window.BW.AUTH_API_REST_PORT
});

const I18nApi = getAxiosInstance({
  url         : window.BW.I18N_API_REST_URL,
  port        : window.BW.I18N_API_REST_PORT,
  versionPath : window.BW.I18N_API_REST_VERSION
});

const ClientApi = getAxiosInstance({
  url         : window.BW.CLIENT_API_REST_URL,
  port        : window.BW.CLIENT_API_REST_PORT,
  versionPath : window.BW.CLIENT_API_REST_VERSION
});

const PressReleaseApi = getAxiosInstance({
  url         : window.BW.PRESS_RELEASE_API_REST_URL,
  port        : window.BW.PRESS_RELEASE_API_REST_PORT,
  versionPath : window.BW.PRESS_RELEASE_API_REST_VERSION
});

const SystemApi = getAxiosInstance({
  url         : window.BW.SYSTEM_API_REST_URL,
  port        : window.BW.SYSTEM_API_REST_PORT,
  versionPath : window.BW.SYSTEM_API_REST_VERSION
});

const FeatureFlagApi = getAxiosInstance({
  url         : window.BW.FEATURE_FLAG_API_REST_URL,
  port        : window.BW.FEATURE_FLAG_API_REST_PORT,
  versionPath : window.BW.FEATURE_FLAG_API_REST_VERSION
});

const MediaApi = getAxiosInstance({
  url         : window.BW.MEDIA_API_REST_URL,
  port        : window.BW.MEDIA_API_REST_PORT,
  versionPath : window.BW.MEDIA_API_REST_VERSION
});

export { OrderApi, AuthApi, I18nApi, ClientApi, PressReleaseApi, SystemApi, FeatureFlagApi, MediaApi };
