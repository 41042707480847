import { pressReleaseActions } from '../actions/actionTypes';

export default function reducer(state = {}, action) {
  switch (action.type) {
    case pressReleaseActions.STORE_PRESS_RELEASES: {
      return {
        ...state,
        user : action.payload
      };
    }
    case pressReleaseActions.STORE_PRESS_RELEASES_BY_STATUS: {
      return {
        ...state,
        ...action.payload
      };
    }
    default:
      return state;
  }
}
