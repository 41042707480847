import React from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import uuid from 'uuid/v4';
import * as R from 'ramda';
import DynamicImport from './DynamicImport';

import Template from '../components/connect5/Template/Template';
import Loading from '../components/connect5/Loading/Loading';

const goToRoute = history => async nextPath => {
  history.push(nextPath);
};

const asyncImport = (customComponent, props, includeTemplate = true) => {
  const { history, authenticatedRoute } = props;
  const routeTo = goToRoute(history);
  const allProps = { ...props, routeTo };
  const templateProps = {
    authenticatedRoute,
    routeTo,
    history
  };

  const loadingComponent = includeTemplate ? (
    <Template {...templateProps}>
      <Loading />
    </Template>
  ) : (
    <Loading />
  );

  const loadComponent = component =>
    includeTemplate ? (
      <Template {...templateProps}>{component}</Template>
    ) : (
      component
    );

  return (
    <DynamicImport load={customComponent}>
      {DynamicComponent =>
        DynamicComponent === null
          ? loadingComponent
          : loadComponent(<DynamicComponent {...allProps} />)
      }
    </DynamicImport>
  );
};
asyncImport.propTypes = {
  authenticatedRoute : PropTypes.bool,
  customComponent    : PropTypes.node,
  history            : PropTypes.shape({}).isRequired,
  includeTemplate    : PropTypes.bool,
  props              : PropTypes.shape({})
};
const getComponent = (props, authenticatedRoute, component, showTemplate) => {
  const allProps = {
    ...props,
    authenticatedRoute
  };
  return asyncImport(component, allProps, showTemplate);
};
// convert routes (from routing/routes.js) to an array of <Route> components
const createRouteComponentsFromRoutes = routes =>
  R.compose(
    R.values,
    R.map(({ authenticatedRoute, component, path, showTemplate }) => (
      <Route
        key={uuid()}
        exact
        path={path}
        component={props =>
          getComponent(props, authenticatedRoute, component, showTemplate)
        }
      />
    ))
  )(routes);

const createRoute404Component = ({
  authenticatedRoute,
  component,
  showTemplate
}) => (
  <Route
    component={props =>
      getComponent(props, authenticatedRoute, component, showTemplate)
    }
  />
);
createRoute404Component.propTypes = {
  authenticatedRoute : PropTypes.bool,
  component          : PropTypes.node,
  showTemplate       : PropTypes.bool
};

export { createRouteComponentsFromRoutes, createRoute404Component };
