import { combineReducers } from 'redux';

import user from './userReducers';
import languages from './languagesReducers';
import { userActions } from '../actions/actionTypes';
import i18n from './i18nReducers';
import pressReleases from './pressReleaseReducers';
import accounts from './accountPickerReducers';
import pagination from './paginationReducers';
import engagementReports from './engagementReportsReducers';
import featureFlags from './featureFlagsReducers';
import timezones from './timezonesReducers';

const appReducer = combineReducers({
  user,
  languages,
  i18n,
  pressReleases,
  accounts,
  pagination,
  engagementReports,
  featureFlags,
  timezones
});

const rootReducer = (state, action) => {
  let newState = state;
  if (action.type === userActions.LOGOUT_USER) {
    newState = undefined;
  }

  return appReducer(newState, action);
};

export default rootReducer;
