import { paginationActions } from '../actions/actionTypes';

const initialState = {
  pageSize   : 10,
  pageNumber : 1
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case paginationActions.STORE_PAGE_SIZE: {
      return {
        ...state,
        pageSize : action.payload
      };
    }
    case paginationActions.STORE_PAGE_NUMBER: {
      return {
        ...state,
        pageNumber : action.payload
      };
    }
    default:
      return state;
  }
}
