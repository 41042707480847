import * as R from 'ramda';
import { getFeatureFlagByFeatureKey } from '../../../helpers/featureFlagActions';
import { setFeatureFlags } from '../../actions/featureFlagsActions';
import initialFeatureFlags from './initialState';

export const fetchFeatureFlags = async dispatch => {
  const initialFeatureFlagsKeys = R.keys(initialFeatureFlags);
  const promises = R.map(featureKey => getFeatureFlagByFeatureKey(featureKey))(initialFeatureFlagsKeys);
  const arrayOfResponses = await Promise.all(promises);
  const featureFlags = R.zipObj(initialFeatureFlagsKeys, arrayOfResponses);
  dispatch(setFeatureFlags(featureFlags));
  return featureFlags;
};
