import * as R from 'ramda';
import { errors } from '@businesswire/bw-node-error';
import { logOut, setExitErrorCodeToStorage } from './userActions';

const handleDefaultError = R.prop('error');

const handleUnauthenticated = ({ error }) => {
  logOut(() => setExitErrorCodeToStorage(error.code));
  return error;
};

const handleInvalidCredentials = R.prop('error');

const handleIsUnauthenticated = R.prop('error');

const handleError = ({ error, source }) => {
  if (error && error.code) {
    const { code } = error;
    const {
      security : {
        REQUEST_ID_MISMATCH
      },
      auth : {
        INVALID_CREDENTIALS,
        TOKEN_INVALID,
        REFRESH_TOKEN_EXPIRED,
        REFRESH_TOKEN_INVALID,
        USER_REQUIRES_MFA,
        UNAUTHORIZED_API_ACCESS
      }
    } = errors;
    switch (code) {
      case TOKEN_INVALID().code:
      case REFRESH_TOKEN_EXPIRED().code:
      case REFRESH_TOKEN_INVALID().code:
      case REQUEST_ID_MISMATCH().code:
        return handleUnauthenticated({ error, source });
      case INVALID_CREDENTIALS().code:
        return handleInvalidCredentials({ error, source });
      case USER_REQUIRES_MFA().code:
        return handleInvalidCredentials({ error, source });
      case UNAUTHORIZED_API_ACCESS().code:
        return handleIsUnauthenticated({ error, source });
      default:
        return handleDefaultError({ error, source });
    }
  }
  return {
    message : 'Unhandled error in helpers/errorHandler',
    code    : 9999
  };
};

export {
  handleError,
  handleDefaultError,
  handleUnauthenticated,
  handleInvalidCredentials
};
