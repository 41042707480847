import { getLanguagesData } from '../../../helpers/i18nActions';
import { I18nApi } from '../../../api/Api';
import { storeLanguages } from '../../actions/languagesActions';

const loadLanguagesInBackground = dispatch => {
  const fetchLanguages = async () => {
    const response = await getLanguagesData(I18nApi)();
    dispatch(storeLanguages(response || null));
  };

  fetchLanguages();
};

export default loadLanguagesInBackground;
