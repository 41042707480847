import { getProfile } from '../../../helpers/clientActions';
import { setUserTimezoneOffset } from '../../actions/userActions';

const loadUserTimezoneOffsetInBackground = dispatch => {
  const fetchUserTimezoneOffsetInBackground = async () => {
    const response = await getProfile();
    dispatch(setUserTimezoneOffset(response?.timezoneOffset));
  };

  fetchUserTimezoneOffsetInBackground();
};

export default loadUserTimezoneOffsetInBackground;
