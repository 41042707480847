import { timezonesActions } from './actionTypes';

const storeReset = () => ({
  type : timezonesActions.STORE_TIMEZONES_RESET
});

const storeTimezones = payload => ({
  type : timezonesActions.STORE_TIMEZONES_DATA,
  payload
});

export {
  storeReset,
  storeTimezones
};
