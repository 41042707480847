import { getStorageItem, removeStorageItem, setStorageItem } from './storage';

import {
  storageKeys,
  reduxStorageKeys,
  untrackedStorageKeys
} from '../utils/localStorage';
import { authRoutes } from '../api/ApiRoutes';

const setUserToStorage = async user => {
  await setStorageItem({
    key   : storageKeys.BW_USER,
    value : user
  });
};

const setAuthTokenToStorage = async token => {
  await setStorageItem({
    key   : storageKeys.BW_TOKEN,
    value : token
  });
};

const setRefreshTokenToStorage = async token => {
  await setStorageItem({
    key   : storageKeys.BW_REFRESH_TOKEN,
    value : token
  });
};

const setPublicKeysToStorage = async keys => {
  await setStorageItem({
    key   : storageKeys.BW_PUBLIC_KEYS,
    value : keys
  });
};

const setDeploymentToStorage = async deployment => {
  await setStorageItem({
    key   : storageKeys.BW_DEPLOYMENT,
    value : deployment
  });
};

const setExitErrorCodeToStorage = async errorCode => {
  await setStorageItem({
    key   : untrackedStorageKeys.EXIT_ERROR_CODE,
    value : errorCode
  });
};

const setAuthDataToStorage = async headers => {
  const authToken = headers['x-auth-token'];
  const refreshToken = headers['x-refresh-token'];
  const publicKeys = headers['x-auth-public-keys'];
  const deployment = headers['x-deployment'];

  await setAuthTokenToStorage(authToken);
  await setRefreshTokenToStorage(refreshToken);
  await setPublicKeysToStorage(publicKeys);
  await setDeploymentToStorage(deployment);
};

const logOut = (callback = () => {}) => {
  removeStorageItem(storageKeys.BW_TOKEN);
  removeStorageItem(untrackedStorageKeys.BW_MFA_TOKEN);
  removeStorageItem(storageKeys.BW_REFRESH_TOKEN);
  removeStorageItem(storageKeys.BW_PUBLIC_KEYS);
  removeStorageItem(storageKeys.BW_USER);
  removeStorageItem(storageKeys.BW_DEPLOYMENT);
  removeStorageItem(reduxStorageKeys.BW_REDUX_PERSIST);
  removeStorageItem(storageKeys.ACCESS_DENIED_URL);
  callback();
};

const getUser = () => getStorageItem(storageKeys.BW_USER) || { language : 'en' };

const getAuthToken = () => getStorageItem(storageKeys.BW_TOKEN);

const getPublicKeys = () => getStorageItem(storageKeys.BW_PUBLIC_KEYS);

const getRefreshToken = () => getStorageItem(storageKeys.BW_REFRESH_TOKEN);

const getDeployment = () => getStorageItem(storageKeys.BW_DEPLOYMENT);

const getDeniedAccessUrl = () => getStorageItem(storageKeys.ACCESS_DENIED_URL);

const removeDeniedAccessUrl = () => removeStorageItem(storageKeys.ACCESS_DENIED_URL);

const getExitErrorCode = () =>
  getStorageItem(untrackedStorageKeys.EXIT_ERROR_CODE);
const clearExitErrorCode = () =>
  removeStorageItem(untrackedStorageKeys.EXIT_ERROR_CODE);

const isLoggedIn = () => {
  const user = getUser();
  const token = getAuthToken();

  /* istanbul ignore next line */
  return !!user && !!user.id && !!token;
};

const checkPermission = AuthApi => async ({
  cloudUserId,
  resource = 'bw-dashboard-web',
  requestType = 'GET'
}) => {
  let possiblyInferredCloudUserId = cloudUserId;

  if (!cloudUserId) {
    possiblyInferredCloudUserId = (getUser() || { id : 0 }).id;
  }

  try {
    const {
      data: {
        data: { hasPermission }
      }
    } = await AuthApi.get(
      authRoutes.CHECK_PERMISSION(
        resource,
        requestType,
        possiblyInferredCloudUserId
      )
    );

    return hasPermission === 1;
  } catch (e) {
    return false;
  }
};

const maybeLoginWithRefreshToken = AuthApi => async () => {
  const refreshToken = getRefreshToken();
  const {
    data: { data: user },
    headers
  } = await AuthApi.post(authRoutes.REFRESH(), {
    refreshToken
  });
  await setUserToStorage(user);
  await setAuthDataToStorage(headers);
  return user;
};

const ensurePermission = async ({ user, AuthApi, success, fail }) => {
  const hasPermission = await checkPermission(AuthApi)({
    cloudUserId : user.id,
    resource    : 'bw-dashboard-web',
    requestType : 'GET'
  });

  // eslint-disable-next-line no-unused-expressions
  return hasPermission ? success() : fail();
};

export {
  logOut,
  maybeLoginWithRefreshToken,
  setAuthTokenToStorage,
  setRefreshTokenToStorage,
  setPublicKeysToStorage,
  setDeploymentToStorage,
  setExitErrorCodeToStorage,
  clearExitErrorCode,
  getExitErrorCode,
  getUser,
  getAuthToken,
  getRefreshToken,
  getPublicKeys,
  getDeployment,
  isLoggedIn,
  checkPermission,
  ensurePermission,
  getDeniedAccessUrl,
  removeDeniedAccessUrl
};
