import { featureFlagsActions } from './actionTypes';

const setFeatureFlags = payload => ({
  type : featureFlagsActions.SET_FEATURE_FLAGS,
  payload
});

export {
  setFeatureFlags
};
