import { userActions } from './actionTypes';

const setUser = payload => ({
  type : userActions.SET_USER,
  payload
});

const logoutUser = payload => ({
  type : userActions.LOGOUT_USER,
  payload
});

const setPreferences = payload => ({
  type : userActions.SET_PREFERENCES,
  payload
});

const bannerAgreement = payload => ({
  type : userActions.BANNER_AGREEMENT,
  payload
});

const setUserTimezoneOffset = payload => ({
  type : userActions.SET_USER_TIMEZONE_OFFSET,
  payload
});

export {
  setUser,
  logoutUser,
  setPreferences,
  setUserTimezoneOffset,
  bannerAgreement
};
